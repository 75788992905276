import cx from 'classnames'
import dynamic from 'next/dynamic'

import { type SanityFreeform } from '@data/sanity/queries/types/blocks'

const ComplexPortableText = dynamic(
  () => import('@components/complex-portable-text')
)

type FreeformProps = SanityFreeform

const Freeform = ({ maxWidth, textAlign, content }: FreeformProps) => {
  if (!content) {
    return null
  }

  return (
    <ComplexPortableText
      content={content}
      className={cx('rc', maxWidth, textAlign)}
    />
  )
}

export default Freeform
